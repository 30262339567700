@import "src/scss/module";

.hero {
  position: relative;
  min-height: 468px;
  width: calc(100vw - 40px);
  margin: 3vh 20px;
  z-index: 0;
  border-radius: 24px;
  overflow: hidden;

  .bg_image {
    position: absolute;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    filter: grayscale(1) brightness(1.5);
  }

  .bg_video {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  .content {
    color: $white;
    position: relative;
    min-height: 468px;
    z-index: 2;
    padding: 0 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 950px;

    .description {
      margin-top: 24px;
    }
  }
}

@media (max-width: 768px) {
  .hero {
    min-height: 368px;

    .bg_video {
      height: 100%;
      width: auto;
    }

    .content {
      min-height: 368px;
    }
  }
}

@media (max-width: 576px) {
  .hero {
    margin: 6px;
    width: calc(100vw - 12px);
  }
}